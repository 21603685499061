import React from 'react'
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { Content, Copy, Cover, CoverImg, Heading, Split, Title } from '../../pages/activities';
import { activeFormImage } from '../../state/atoms';
import { MainButton } from '../../styles/buttons';

export default function MeetingPlaceItem({ index, title, name, description, image, showMeeting }) {
    const setActiveImage = useSetRecoilState(activeFormImage)


    return (
        <Split>
            <MeetingContent odd={index % 2 === 1}>
                {title && <Title>{title}</Title>}
                <Heading>{name}</Heading>
                <Copy>
                    {description}
                </Copy>
                <MainButton
                    style={{ marginBottom: 30 }}
                    onClick={() => {
                        setActiveImage(image)
                        showMeeting(true)
                    }}
                >
                    Request a Quote
                </MainButton>
            </MeetingContent>
            <MeetingCover odd={index % 2 === 1}>
                <MeetingImage
                    src={image}
                />
            </MeetingCover>
        </Split>
    )
}

const MeetingImage = styled(CoverImg)`
    max-height: 450px;
    height: 400px;
    object-fit: cover;

    @media (max-width: 768px) {
        order: 0;
        height: 350px;
        max-height: initial;
        margin-bottom: 20px;
    }
`

const MeetingContent = styled(Content)`
    order: ${props => props.odd ? 0 : 1};

    @media (max-width: 768px) {
        order: 1;
    }

`;
const MeetingCover = styled(Cover)`
    order: ${props => props.odd ? 1 : 0};

    @media (max-width: 768px) {
        order: 0;
    }
`;
