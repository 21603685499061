import React from "react"
import { useSetRecoilState } from "recoil"
import styled from "styled-components"
import FooterGallery from "../components/footer-gallery"
import Layout from "../components/layout"
import MeetingPlaceItem from "../components/meetings-and-events/meeting-place-item"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block"
import { showingMeetingForm } from "../state/atoms"
import { MainButton } from "../styles/buttons"
import { colors } from "../styles/colors"
import {
    Content,
    Copy,
    Cover,
    CoverImg,
    Heading,
    Split,
    Title,
} from "./activities"

const Container = styled.div``

const MeetingsPage = ({ data }) => {
    const showMeeting = useSetRecoilState(showingMeetingForm)
    const pageData = data.wpPage.meetings_and_events

    const {
        footerGallery,
        title,
        subtitle,
        indoorLocations,
        outdoorsLocations
    } = pageData

    return (
        <Layout>
            <SEO title="Meetings and Events" />
            <PageHeader title={title} />
            <TitleBlock
                title={title}
                copy={subtitle}
            />
            <Container>
                <div style={{ backgroundColor: colors.base, color: "white" }}>
                    {indoorLocations.map((item, index) => (
                        <MeetingPlaceItem
                            key={index}
                            index={index}
                            title={index === 0 && "Indoors"}
                            name={item.name}
                            image={item.image.sourceUrl}
                            showMeeting={showMeeting}
                            description={item.description}
                        />
                    ))}
                </div>
                {outdoorsLocations.map((item, index) => (
                    <MeetingPlaceItem
                        key={index}
                        index={index}
                        title={index === 0 && "Outdoors"}
                        name={item.name}
                        image={item.image.sourceUrl}
                        showMeeting={showMeeting}
                        description={item.description}
                    />
                ))}
                {footerGallery && footerGallery.length > 0 &&
                    <FooterGallery gallery={footerGallery} />
                }
            </Container>
        </Layout>
    )
}

export default MeetingsPage

const MeetingImage = styled(CoverImg)`
    max-height: 450px;
    height: 400px;
`

export const MeetingQuery = graphql`
    query meetingsQuery {
        wpPage(title: { eq: "Meetings and Events" }) {
            meetings_and_events {
                footerGallery {
                    sourceUrl
                }
                subtitle
                title
                indoorLocations {
                  description
                  name
                  image {
                    sourceUrl
                  }
                }
                outdoorsLocations {
                  image {
                    sourceUrl
                  }
                  name
                  description
                }
              }

        }
    }
`

